<form [formGroup]="groupCreationForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
      <h2>{{ getTitle() }}</h2>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
        <span aria-hidden="false">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger" role="alert" *ngIf="error">
        {{ error }}
      </div>
      <div class="container">
          <div class="col">
            <div class="form-group">
                <label i18n>Group name:
                    <input type="text" class="form-control input-text ml-3" 
                        placeholder="Group name"
                        formControlName="name"
                        [class.is-valid]="groupCreationForm.controls.name.valid && groupCreationForm.controls.name.touched"
                        [class.is-invalid]="groupCreationForm.controls.name.invalid && groupCreationForm.controls.name.touched" />
                    <div class="invalid-feedback">
                        <ng-container *ngTemplateOutlet="defaultNameTemplate; context: { $implicit: groupCreationForm.controls.name.errors }"></ng-container>
                    </div>
                </label>
            </div>
          </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" [disabled]="!groupCreationForm.valid" class="btn btn-primary" i18n><span class="fas fa-save"></span> Submit</button>
    </div>
</form>

<!-- Default templates -->
<ng-template #defaultNameTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
  </ul>
</ng-template>



<form [formGroup]="standardModeForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h5 class="modal-title">{{ getTitle() }}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>
        <span i18n>Setup list</span>
        <select class="form-control mt-1" formControlName="setup"
        [class.is-valid]="standardModeForm.controls.setup.valid && standardModeForm.controls.setup.touched"
        [class.is-invalid]="standardModeForm.controls.setup.invalid && standardModeForm.controls.setup.touched">
          <option class="dropdown-item" disabled selected value i18n>Select an Option</option>
          <option class="dropdown-item" *ngFor="let set of setups" [value]="set"> {{ set }}</option>
        </select>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" [disabled]="disableSubmit()" class="btn btn-primary submit" i18n><span class="fas fa-save"></span> Save</button>
  </div>
</form>

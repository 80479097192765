import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GENERAL_SETTINGS_SERVICE, IGeneralSettingsService } from '../service-configuration';
import { Subscription } from 'rxjs';
import { GeneralSettingsDefinition } from '../general-settings-definition';

const MAX_VALUE = 3600;
const MIN_VALUE = 1;

@Component({
  selector: 'cwi-remote-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnDestroy {

  private subscription = new Subscription();
  maxValue = MAX_VALUE;
  minValue = MIN_VALUE;
  generalOptionsForm: FormGroup;
  settings: GeneralSettingsDefinition;

  constructor(
    @Inject(GENERAL_SETTINGS_SERVICE)
    private readonly generalSettingsService: IGeneralSettingsService,
  ) {
    this.generalOptionsForm = new FormGroup({
      delayStartOnStableSupply: new FormControl(null, [Validators.required, Validators.max(this.maxValue), Validators.min(this.minValue)]),
      delayShutdownOnUnstableSupply: new FormControl(null, [Validators.required, Validators.max(this.maxValue), Validators.min(this.minValue)])
    });

    this.subscription.add(generalSettingsService.settings$.subscribe(settings => {
      if (settings) {
        this.settings = settings;
        this.setPreviousValues();
      }
    }));
  }

  setPreviousValues() {
    this.generalOptionsForm.get('delayStartOnStableSupply').setValue(this.settings.delayStartOnStableSupply);
    this.generalOptionsForm.get('delayShutdownOnUnstableSupply').setValue(this.settings.delayShutdownOnUnstableSupply);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async onSubmitGeneralOptions() {
    if (this.generalOptionsForm.valid) {
      const result: GeneralSettingsDefinition = {
        delayStartOnStableSupply: this.generalOptionsForm.get('delayStartOnStableSupply').value,
        delayShutdownOnUnstableSupply: this.generalOptionsForm.get('delayShutdownOnUnstableSupply').value
      };
      await this.generalSettingsService.updateOptions(result).toPromise();
      await this.generalSettingsService.reload();
      this.generalOptionsForm.reset();
    }
  }
}

<form [formGroup]="userCreationForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
      <h2 i18n>Create User</h2>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
        <span aria-hidden="false">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger" role="alert" *ngIf="error">
        {{ error }}
      </div>
      <div class="container">
          <div class="col">
            <div class="form-group">
                <label i18n>Username:
                    <input type="text" class="form-control input-text ml-3"
                        i18n-placeholder
                        placeholder="[Domain\]Username"
                        formControlName="userName"
                        [class.is-valid]="userCreationForm.controls.userName.valid && userCreationForm.controls.userName.touched"
                        [class.is-invalid]="userCreationForm.controls.userName.invalid && userCreationForm.controls.userName.touched" />
                    <div class="invalid-feedback">
                        <ng-container *ngTemplateOutlet="defaultUsernameTemplate; context: { $implicit: userCreationForm.controls.userName.errors }"></ng-container>
                    </div>
                </label>
            </div>
        
            <div class="form-group">
                <label i18n>Display name:
                    <input type="text" class="form-control input-text ml-3"
                        i18n-placeholder
                        placeholder="Display name"
                        formControlName="displayName"
                        [class.is-valid]="userCreationForm.controls.displayName.valid && userCreationForm.controls.displayName.touched"
                        [class.is-invalid]="userCreationForm.controls.displayName.invalid && userCreationForm.controls.displayName.touched" />
                    <div class="invalid-feedback">
                        <ng-container *ngTemplateOutlet="defaultDisplayNameTemplate; context: { $implicit: userCreationForm.controls.displayName.errors }"></ng-container>
                    </div>
                </label>
            </div>
  
            <div class="form-group">
                <label i18n>Email:
                  <input type="email" class="form-control input-text ml-3"
                      i18n-placeholder
                      placeholder="Email"
                      formControlName="email"
                      [class.is-valid]="userCreationForm.controls.email.valid && userCreationForm.controls.email.touched"
                      [class.is-invalid]="userCreationForm.controls.email.invalid && userCreationForm.controls.email.touched" />
                  <div class="invalid-feedback">
                      <ng-container *ngTemplateOutlet="defaultEmailTemplate; context: { $implicit: userCreationForm.controls.email.errors }"></ng-container>
                  </div>
                </label>
            </div>
          </div>
          <div class="col">        
            <div class="form-group">
                <label i18n>Password:
                  <input [type]="'password'" class="form-control input-text ml-3"
                  i18n-placeholder
                  placeholder="Password" 
                  formControlName="password"
                  [class.is-valid]="userCreationForm.controls.password.valid && userCreationForm.controls.password.touched"
                  [class.is-invalid]="userCreationForm.controls.password.invalid && userCreationForm.controls.password.touched" />
                  <div class="invalid-feedback">
                      <ng-container *ngTemplateOutlet="defaultPasswordTemplate; context: { $implicit: userCreationForm.controls.password.errors }"></ng-container>
                  </div>
                </label>
            </div>
        
            <div class="form-group">
                <label i18n>Confirm password:
                    <input [type]="'password'" class="form-control input-text ml-3"
                    i18n-placeholder
                    placeholder="Confirm password" 
                    formControlName="confirmPassword"
                    [class.is-valid]="userCreationForm.controls.confirmPassword.valid && userCreationForm.controls.confirmPassword.touched"
                    [class.is-invalid]="userCreationForm.controls.confirmPassword.invalid && userCreationForm.controls.confirmPassword.touched" />
                    <div class="invalid-feedback">
                      <ng-container *ngTemplateOutlet="defaultPasswordTemplate; context: { $implicit: userCreationForm.controls.confirmPassword.errors }"></ng-container>
                    </div>
                </label>
            </div>
          </div>
      </div>
      <div class="text-danger">
        <ng-container *ngTemplateOutlet="defaultConfirmPasswordTemplate; context: { $implicit: userCreationForm.errors }"></ng-container>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" [disabled]="!userCreationForm.valid" class="btn btn-primary" i18n><span class="fas fa-save"></span> Submit</button>
    </div>
</form>

<!-- Default templates -->
<ng-template #defaultPasswordTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
    <li *ngIf="errors.uppercase" i18n>Must contain an uppercase letter</li>
    <li *ngIf="errors.lowercase" i18n>Must contain a lowercase letter</li>
    <li *ngIf="errors.number" i18n>Must contain a number</li>
  </ul>
</ng-template>

<ng-template #defaultConfirmPasswordTemplate let-errors>
  <ul *ngIf="errors && userCreationForm.controls.password.touched && userCreationForm.controls.confirmPassword.touched">
    <li *ngIf="errors.notSamePassword" i18n>The passwords do not match</li>
  </ul>
</ng-template>

<ng-template #defaultUsernameTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
    <li *ngIf="errors.specialChars" i18n>Only characters, numbers and some special characters (<em> \ - . _ </em>) are allowed </li>
  </ul>
</ng-template>

<ng-template #defaultDisplayNameTemplate let-errors>
    <ul *ngIf=errors>
      <li *ngIf="errors.required" i18n>This field is required</li>
      <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
      <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
      <li *ngIf="errors.specialChars" i18n>Only characters, numbers and some special characters (<em> \ - . _ </em>) are allowed </li>
    </ul>
</ng-template>

<ng-template #defaultEmailTemplate let-errors>
  <ul *ngIf="errors">
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.email" i18n>Enter a valid email</li>
  </ul>
</ng-template>



<div class="card mb-1">
    <div class="card-body card-body-cwi">
        <div class="button-container">
            <button *ngIf="isClickable" class="button-text btn btn-sm btn-outline-primary" (click)="$event.stopPropagation(); planClick()">
                {{ planItem.name }}
            </button>
            <span *ngIf="planItemError?.planName === planItem.name" style="margin:0 auto;" 
                class="fas ml-2 fa-exclamation-triangle text-warning"
                title="{{ planItemError.error }}">
            </span>
        </div>
        <h6 *ngIf="!isClickable" class="button-text text-primary font-weight-bold">{{ planItem.name }}</h6>
        <h6 class="text-center text-secondary mt-1">{{ planItem.description }}</h6>
        <div class="text-center text-secondary">
            <label class="font-weight-normal mr-2" i18n> Mode: {{ planItem.workingMode }}</label>
            <i *ngIf="planItem.workingMode === 'standard'" class="fas fa-bolt" ></i>
            <i *ngIf="planItem.workingMode === 'geometry'" class="fas fa-drafting-compass"></i>
            <i *ngIf="planItem.workingMode === 'speed'" class="fas fa-tachometer-alt"></i>
        </div>
        <div class="button-container" >
            <button type="button" class="btn btn-sm text-primary buttons"
                cwiClickSink
                triggers=manual
                container=body 
                autoClose=outside
                title="Edit"
                (click)="editPlanModal($event);">
                <span class="fas fa-pencil-alt"></span>&nbsp;
            </button>
            <button [disabled]="isActive" type="button" class="btn btn-sm text-danger buttons"
                cwiClickSink
                triggers=manual
                container=body 
                autoClose=outside
                title="Delete"
                popoverTitle="Are you sure?" 
                [ngbPopover]="confirmDelete" 
                #confirmPopover="ngbPopover"
                (click)="deleteSchedulation(confirmPopover)">
                <span class="fas fa-trash"></span>&nbsp;
            </button>
            <mat-slide-toggle style="margin-left: 5rem;"
                title="Activate/Deactivate plan"
                [color]="'background'"
                [checked]="isActive"
                [disabled]="toggleDisabled"
                (click)="togglePlan($event)">
            </mat-slide-toggle>
        </div>
    </div>
</div>

<ng-template let-close=close #confirmDelete>
    <button class="btn btn-sm btn-outline-primary mr-2" (click)="close(true)" i18n>Confirm</button>
    <button class="btn btn-sm btn-outline-danger" (click)="close(false)" i18n>Cancel</button>
</ng-template>
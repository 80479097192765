<div class="modal-header">
  <h5 class="modal-title" i18n>Sort schedulations priority</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div cdkDropList
  class="example-list"
  (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of schedulationsInternal" cdkDrag i18n>
      {{ item.setupName }} - from {{ item.startTime | cwiSecondsToHHmm }} to {{ item.endTime | cwiSecondsToHHmm }}
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="!edited" class="btn btn-primary" (click)="save();" i18n>
    <span class="fas fa-save"></span> Save
  </button>
  <button type="button" class="btn btn-danger mr-2 cancel" (click)="cancel();" ngbAutofocus i18n>Cancel
  </button>
</div>

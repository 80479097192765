<div class="modal-header">
  <h5 class="modal-title" i18n>Confirm operation</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p> {{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="confirm()" i18n>Confirm</button>
  <button type="button" class="btn btn-danger" (click)="close()" i18n>Cancel</button>
</div>
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserInfo } from '../../models/user-definition';
import { IUserCreationConfiguration, USER_CREATION_CONFIGURATION } from '../../../user-creation.configuration';

@Component({
    selector: 'cwi-user-update',
    templateUrl: './user-update.component.html',
    styleUrls: ['./user-update.component.scss'],
    providers: [],
  })
  export class UserUpdateComponent {

    @Input()
    public error: string;

    @Input()
    public userInfo: UserInfo;

    @Output()
    public readonly passwordUpdated = new EventEmitter<string>();

    @Output()
    public readonly displayNameUpdated = new EventEmitter<string>();

    @Output()
    public readonly emailUpdated = new EventEmitter<string>();

    @Output()
    public readonly closed = new EventEmitter<void>();

    userInfoInternal: UserInfo;
    updatePasswordForm: FormGroup;
    updateEmailForm: FormGroup;
    updateDisplayNameForm: FormGroup;
    message: string;
    isError = false;

    constructor(
      @Inject(USER_CREATION_CONFIGURATION)
      configuration: IUserCreationConfiguration
    ) {
      this.updatePasswordForm = new FormGroup({
        password: new FormControl(null, configuration.passwordValidators),
        confirmPassword: new FormControl(null, configuration.passwordValidators),
      }, { validators: [ this.checkPasswords ]});

      this.updateEmailForm = new FormGroup({
        email: new FormControl(null, configuration.emailValidators),
      });

      this.updateDisplayNameForm = new FormGroup({
        displayName: new FormControl(null, configuration.displaynameValidators),
      });
    }

    checkPasswords(formGroup: FormGroup) {
      const password = formGroup.get('password').value;
      const confirmPassword = formGroup.get('confirmPassword').value;
      return password === confirmPassword ? null : { notSamePassword: true };
    }

    onSubmitEmail() {
      if (this.updateEmailForm.valid) {
        const email = this.updateEmailForm.get('email').value;
        this.emailUpdated.next(email);
        this.updateEmailForm.reset();
      }
    }

    onSubmitPassword() {
      if (this.updatePasswordForm.valid) {
        const password = this.updatePasswordForm.get('password').value;
        this.passwordUpdated.next(password);
        this.updatePasswordForm.reset();
      }
    }

    onSubmitDisplayName() {
      if (this.updateDisplayNameForm.valid) {
        const displayName = this.updateDisplayNameForm.get('displayName').value;
        this.displayNameUpdated.next(displayName);
        this.updateDisplayNameForm.reset();
      }
    }

    closeAlert() {
      this.error = null;
    }

    close() {
      this.closed.next();
    }
}
